<template>
	<div class="com-headr">
		<img class="logo" src="@/assets/logo.png" alt="">
		<img class="logoname" src="@/static/img/pin.png" alt="">
		<div class="flex_row_center">
			<div v-for="(e, i) in navList" @click="toPage(e.id)" :key="i" class="nav" :class="{ 'on': index == e.id }">{{
				e.name ? e.name : '' }}
			</div>
		</div>
		<el-input class="search" placeholder="搜索" v-model.trim="searchInput" width="240px"
			@keyup.enter.native="handleQuery">
			<i slot="suffix" class="el-input__icon el-icon-search" @click="handleQuery()"></i>
		</el-input>
		<div class="flex_row_center opratepart">
			<!-- <div class="btn redbtn" v-if="isLogin" @click="publish()">我要发布</div> -->
			<div v-if="!isLogin" class="btn" @click="showLogin()">登录</div>
			<div v-if="!isLogin" class="btn" @click="register()">注册</div>


			<el-dropdown v-if="isLogin">
				<div class="flex_row_center el-dropdown-link">
					<el-avatar :size="40" :src="avatar ? avatar : defaultimg"></el-avatar>
					<img class="downicon" src="@/static/common/downicon.png" alt="">
				</div>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item>
						<div class="flex_row_left" @click="toMy(1)">
							<img class="dropicon icon1" src="@/static/common/my.png" alt="">
							我的主页
						</div>
					</el-dropdown-item>
					<el-dropdown-item>
						<div class="flex_row_left" @click="toMy(2)">
							<img class="dropicon icon2" src="@/static/common/notice.png" alt="">
							消息通知
						</div>
					</el-dropdown-item>
					<el-dropdown-item>
						<div class="flex_row_left" @click="loginOut()">
							<img class="dropicon icon3" src="@/static/common/out.png" alt="">
							退出登录
						</div>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>

		<transition name="el-fade-in">
			<div v-show="isShowLogin" class="balckmask">
				<div class="box">
					<img @click="closeLogin()" class="close" src="@/static/common/close.png" alt="">
					<!-- <div class="left">
						<img class="mobile" src="@/static/common/mobile.png" alt="">
						<div class="flex_row_left">
							<img class="qrimg" src="@/static/common/qrcode.png" alt="">
							<div @click="isShowDown = true">
								<div class="download">下载红墨笔App ></div>
								<div class="more">查看更多行业资讯</div>
							</div>
						</div>
					</div> -->
					<div class="right" v-show="type == 1">
						<!-- <h1>您好，</h1>
						<h1>欢迎登录红墨笔app！</h1> -->
						<el-input class="logininput" placeholder="请输入手机号" v-model="mobile" width="240px">
							<img slot="prefix" class="inputicon" src="@/static/common/phon.png" alt="">
						</el-input>
						<el-input class="logininput pass" placeholder="请输入密码" v-model="password" show-password>
							<img slot="prefix" class="inputicon" src="@/static/common/password.png" alt="">
							<!-- <el-button slot="suffix" class="codebtn" @click="getVerifyCode()">{{ verify_text
							}}</el-button> -->
						</el-input>
						<el-input class="logininput" placeholder="请输入验证码" v-model="code">
							<img slot="prefix" class="inputicon" src="@/static/common/codeimg.png" alt="">
							<img slot="suffix" @click="chengImg" class="codebtn"
								:src="'https://api.pping.vip/captcha.html?r=' + nowdate" alt="">
						</el-input>
						<div class="loginbtn" @click="bindLogin()">登录</div>
						<div class="loginbtn colorgreen" @click="toRAL(2)">注册</div>
						<!-- <el-divider content-position="center">其他登录方式</el-divider>
						<img class="wechat" src="@/static/common/wechat.png" alt=""> -->
						<div class="flex_row_left">
							<el-checkbox v-model="checked" fill="#EB3535"></el-checkbox>
							<div class="agree">登录代表同意<span class="colorred" @click="toAgree(1)">《用户协议》</span>和<span
									class="colorred" @click="toAgree(2)">《隐私协议》</span>
							</div>
						</div>
					</div>
					<div class="right" v-show="type == 2">
						<!-- <h1>您好，</h1>
						<h1>欢迎注册红墨笔app！</h1> -->
						<el-input class="logininput" placeholder="请输入手机号" v-model="mobile" width="240px">
							<img slot="prefix" class="inputicon" src="@/static/common/phon.png" alt="">
						</el-input>
						<el-input class="logininput pass" placeholder="请输入密码" v-model="password" show-password>
							<img slot="prefix" class="inputicon" src="@/static/common/password.png" alt="">
						</el-input>
						<el-input class="logininput pass" placeholder="请确认密码" v-model="repassword" show-password>
							<img slot="prefix" class="inputicon" src="@/static/common/password.png" alt="">
						</el-input>
						<el-input class="logininput" placeholder="请输入验证码" v-model="code">
							<img slot="prefix" class="inputicon" src="@/static/common/codeimg.png" alt="">
							<img slot="suffix" @click="chengImg" class="codebtn"
								:src="'https://api.pping.vip/captcha.html?r=' + nowdate" alt="">
						</el-input>
						<div class="loginbtn" @click="sureregister()">注册</div>
						<div class="loginbtn colorgreen" @click="toRAL(1)">返回登陆</div>
						<!-- <el-divider content-position="center">其他登录方式</el-divider>
						<img class="wechat" src="@/static/common/wechat.png" alt=""> -->
						<div class="flex_row_left">
							<el-checkbox v-model="checked" fill="#EB3535"></el-checkbox>
							<div class="agree">注册代表同意<span class="colorred" @click="toAgree(1)">《用户协议》</span>和<span
									class="colorred" @click="toAgree(2)">《隐私协议》</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<transition name="el-fade-in">
			<DownLoad v-if="isShowDown" @closeDownLoad="closeDownLoad()"></DownLoad>
		</transition>
	</div>
</template>

<script>
import DownLoad from '../download/download.vue';
import defaultimg from '@/static/home/avatar.png';
import { checkMobile, setToken, setLoginStatus, getLoginStatus, removeToken, setUserInfo, getUserInfo, removeUserInfo } from '@/utils/auth'
export default {
	name: 'pHeader',
	components: {
		DownLoad
	},
	data() {
		return {
			defaultimg,
			searchInput: this.searchWord,
			isShowDown: false,
			checked: false,
			navList: [
				{
					id: 'first',
					name: '首页'
				},
				// {
				// 	id: 1,
				// 	name: '发现'
				// },
				// {
				// 	id: 2,
				// 	name: '律师团'
				// },
				// {
				// 	id: 3,
				// 	name: '口碑优化'
				// },
				{
					id: 'second',
					name: '美妆百科'
				},
				{
					id: 'third',
					name: '美妆心得'
				},
				{
					id: 'fourth',
					name: '美妆展会'
				},
				{
					id: 'five',
					name: '付费资讯'
				},
			],
			// isShowLogin: false,
			second: 0,
			mobile: '',
			password: '',
			repassword: '',
			type: 1,
			nowdate: 0.314,
			code: ''
		};
	},
	props: {
		index: {
			type: String,
			default: 'first',
		},
		searchWord: {
			type: String,
			default: ''
		}
	},
	computed: {
		verify_text() {
			if (this.second == 0) {
				return '获取验证码';
			} else {
				if (this.second < 10) {
					return '0' + this.second + '秒后重新获取';
				} else {
					return this.second + '秒后重新获取';
				}
			}
		},
		// isLogin() {
		// 	if (getLoginStatus() == 'true') {
		// 		console.log(getLoginStatus())
		// 		return true;
		// 	} else {
		// 		return false;
		// 	}
		// },
		isLogin: {
			get() {
				return this.$store.state.isLogin
			},
			set(val) { },
		},
		avatar: {
			get() {
				return this.$store.state.avatar
			},
			set(val) { },
		},
		isShowLogin: {
			get() {
				return this.$store.state.isShowLogin
			},
			set(val) { },
		},
	},
	created: function () {
		if (getLoginStatus() && getLoginStatus() == 'true') {
			this.$store.commit('CHANGELOGINSTATUS', true);
			this.$store.commit('UPDATAINFO', getUserInfo());
		} else {
			this.$store.commit('CHANGELOGINSTATUS', false);
			removeUserInfo()
		}
		this.chengImg()
	},
	methods: {
		toRAL(i) {
			this.type = i;
			this.chengImg();
			this.mobile = '';
			this.password = '';
			this.repassword = '';
			this.code = '';
		},
		chengImg() {
			this.nowdate = new Date().getTime();
			this.$forceUpdate()
		},
		toAgree(id) {
			// this.$router.push('/agreement?id=' + id)
			let routeData = this.$router.resolve({
				path: "/agreement",
				query: { id: `${id}` },
			});
			window.open(routeData.href, "_blank");
		},
		closeLogin() {
			this.$store.commit('CHANGESHOWLOGIN', false)
		},
		getUserInfo() {
			this.axios.post(this.$Api.user.getUserInfo, {
			}).then(res => {
				// if (res.code == 1) {
				console.log(res)
				// }
			}, err => {
				console.log(err)
			})
		},
		/*获取验证码*/
		getVerifyCode() {
			let that = this;
			if (this.second > 0) {
				return;
			}
			if (!checkMobile(this.mobile)) {
				this.$message.error('请输入正确手机号');
				return;
			}

			this.axios.post(this.$Api.common.verify, {
				mobile: this.mobile
			}).then(res => {
				if (res.code == 1) {
					this.$message.success('获取成功');
					this.second = 60;
					let js = setInterval(function () {
						that.second--;
						if (that.second == 0) {
							clearInterval(js)
						}
					}, 1000)
				}
			})
		},
		// 手机登录
		bindLogin() {
			if (!this.checked) {
				this.$message.error('请勾选同意用户协议和隐私政策');
				return;
			}
			if (!checkMobile(this.mobile)) {
				this.$message.error('请输入正确手机号');
				return;
			} else if (this.password == '') {
				this.$message.error('请输入验证码');
				return;
			}
			if (!this.code) {
				this.$message.error('请输入图形验证码');
				return;
			}
			this.axios.post(this.$Api.user.login, {
				account: this.mobile,
				password: this.password,
				code: this.code
			}).then(res => {
				if (res.code == 1) {
					this.$message.success('登录成功');
					let userInfo = res.data.userinfo;
					setToken(userInfo.token);
					setUserInfo(userInfo)
					setLoginStatus('true')
					this.$store.commit('CHANGELOGINSTATUS', true);
					this.$store.commit('UPDATAINFO', userInfo);
					// this.isShowLogin = false;
					this.$store.commit('CHANGESHOWLOGIN', false)
				} else {
					this.$store.commit('CHANGELOGINSTATUS', false)
					this.$message.error(res.msg);
				}
			})
		},
		loginOut() {
			this.$confirm('确定要退出登录吗?', '提示', {
				cancelButtonText: '取消',
				confirmButtonText: '确定',
				type: 'warning'
			}).then(() => {
				setLoginStatus('false')
				removeToken()
				removeUserInfo()
				this.$store.commit('CHANGELOGINSTATUS', false)
				this.$message({
					type: 'success',
					message: '退出成功!'
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消退出'
				});
			});
		},
		publish() {
			this.$router.push('/publish')
		},
		closeDownLoad() {
			this.isShowDown = false;
		},
		showLogin() {
			// this.isShowLogin = true
			this.$store.commit('CHANGESHOWLOGIN', true)
			this.type = 1;
		},
		register() {
			this.$store.commit('CHANGESHOWLOGIN', true)
			this.type = 2;
		},
		sureregister() {
			if (!this.checked) {
				this.$message.error('请勾选同意用户协议和隐私政策');
				return;
			}
			if (!checkMobile(this.mobile)) {
				this.$message.error('请输入正确手机号');
				return;
			}
			if (this.password == '' || this.repassword == '') {
				this.$message.error('请输入密码');
				return;
			}
			if (this.password != this.repassword) {
				this.$message.error('密码输入不一致');
				return;
			}
			if (!this.code) {
				this.$message.error('请输入图形验证码');
				return;
			}
			this.axios.post(this.$Api.user.register, {
				mobile: this.mobile,
				password: this.password,
				code: this.code
			}).then(res => {
				if (res.code == 1) {
					this.$message.success('注册成功');
					this.$store.commit('CHANGESHOWLOGIN', true)
					this.type = 1;
					this.mobile = '';
					this.password = '';
					this.repassword = '';
					this.code = '';
				} else {
					this.$message.error(res.msg);
				}
			})
		},
		handleQuery() {
			if (this.searchInput) {
				this.$router.push('/search?searchWord=' + this.searchInput)
			}
		},
		toPage(i) {
			if (i == 0) {
				this.$router.push('/')
			} else {
				// this.$router.push('/?activeName=' + i)
				this.$nextTick(() => {
					this.$router.replace({
						path: '/?activeName=' + i
					})
				})
			}
			// else if (i == 1) {
			// 	this.$router.push('/find')
			// } else if (i == 2) {
			// 	this.$router.push('/lawyers')
			// } else if (i == 3) {
			// 	this.$router.push('/optimize')
			// }
		},
		toMy(i) {
			if (i == 1) {
				this.$router.push('/center')
			} else if (i == 2) {
				this.$router.push('/message')
			}
		}
	}
};
</script>

<style scoped>
.com-headr {
	width: 100%;
	min-width: 1200px;
	height: 56px;
	background: #FFFFFF;
	box-shadow: 0px 1px 3px 0px rgba(174, 174, 174, 0.25);
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo {
	width: 32px;
	height: 32px;
	margin-right: 10px;
}

.logoname {
	max-width: 112px;
	height: 32px;
	margin-right: 52px;
}

.nav {
	white-space: nowrap;
	font-size: 18px;
	font-family: Source Han Sans CN-Normal, Source Han Sans CN;
	font-weight: 400;
	color: #666666;
	margin-right: 32px;
	cursor: pointer;
}

.on {
	color: #EB3535;
}

.search {
	width: 240px;
	height: 32px;
}

.search ::v-deep .el-input__inner {
	width: 240px;
	height: 32px;
	background: #EEEEEE;
	border-radius: 23px 23px 23px 23px;
	margin-left: 20px;
}

.search ::v-deep .el-input__suffix {
	top: -3px;
}

.pass ::v-deep .el-input__suffix {
	top: 10px;
}

.opratepart {
	margin-left: 236px;
}

.btn {
	margin-right: 18px;
	box-sizing: border-box;
	padding: 4px 16px;
	white-space: nowrap;
	background: #FFFFFF;
	border-radius: 19px 19px 19px 19px;
	border: 1px solid #999999;
	cursor: pointer;
}

.redbtn {
	color: #FFFFFF;
	background: #EB3535;
	border: 1px solid #EB3535;
}

.box {
	width: 420px;
	height: 530px;
	background: #FFFFFF;
	border-radius: 6px 6px 6px 6px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.close {
	width: 26px;
	height: 26px;
	position: absolute;
	top: 12px;
	right: 12px;
}

.left {
	width: 50%;
	height: 444px;
	border-right: 1px solid #EBEBEB;
	box-sizing: border-box;
	padding: 10px 56px 6px 80px;
}

.right {
	/* width: 50%; */
	box-sizing: border-box;
	/* padding: 0 42px; */
	flex: 1;
	padding: 0 50px;
}

.mobile {
	width: 270px;
	height: 330px;
	margin-bottom: 30px;
}

.qrimg {
	width: 68px;
	height: 68px;
	margin: 0 32px 0 18px;
}

.download {
	height: 24px;
	font-size: 16px;
	font-family: Source Han Sans CN-Normal, Source Han Sans CN;
	font-weight: 400;
	color: #333333;
	line-height: 24px;
	cursor: pointer;
}

.more {
	font-size: 14px;
	font-family: Source Han Sans CN-Normal, Source Han Sans CN;
	font-weight: 400;
	color: #666666;
	line-height: 21px;
	margin-top: 5px;
	cursor: pointer;
}

h1 {
	font-size: 22px;
	font-family: Source Han Sans CN-Medium, Source Han Sans CN;
	font-weight: 500;
	color: #1F2128;
	line-height: 16px;
	letter-spacing: 2px;
}

.logininput ::v-deep .el-input__inner {
	width: 323px;
	height: 48px;
	background: #FFFFFF;
	border-radius: 12px 12px 12px 12px;
	opacity: 1;
	border: 1px solid #1F2128;
	margin-top: 20px;
	padding-left: 45px;
}

.inputicon {
	width: 20px;
	height: 20px;
	margin-top: 35px;
	margin-left: 15px;
}

.codebtn {
	width: 126px;
	height: 48px;
	background: rgba(31, 33, 40, 0);
	border-radius: 12px 12px 12px 12px;
	border: 1px solid #1F2128;
	margin-top: 20px;
	font-size: 14px;
	font-family: Source Han Sans CN-Regular, Source Han Sans CN;
	font-weight: 400;
	color: #EB3535;
	margin-right: -5px;
}

.loginbtn {
	width: 323px;
	height: 48px;
	line-height: 48px;
	background: #EB3535;
	box-shadow: 0px 10px 35px 0px rgba(201, 111, 111, 0.25);
	border-radius: 12px;
	font-size: 18px;
	font-family: Source Han Sans CN-Regular, Source Han Sans CN;
	font-weight: 400;
	color: #FFFFFF;
	text-align: center;
	cursor: pointer;
	margin: 32px 0;
}

.colorgreen {
	background: #35a8eb;
}

.wechat {
	width: 42px;
	height: 42px;
	margin: 20px calc(50% - 21px);
}

.agree {
	font-size: 12px;
	font-family: Source Han Sans CN-Regular, Source Han Sans CN;
	font-weight: 400;
	color: #999999;
	margin-left: 4px;
}

.colorred {
	color: #EB3535;
	cursor: pointer;
}

::v-deep .el-checkbox__inner {
	border-radius: 50%;
}

.downicon {
	width: 12px;
	height: 8px;
	margin-left: 6px;
}

.dropicon {
	width: 18px;
	height: 18px;
	margin-right: 8px;
}

::v-deep.el-dropdown-menu__item:focus,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover {
	color: #EB3535;
	background: #FFEDED;
	white-space: nowrap;
}

::v-deep.el-dropdown-menu__item:focus .icon1,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover .icon1 {
	content: url(@/static/common/myed.png);
}

::v-deep.el-dropdown-menu__item:focus .icon2,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover .icon2 {
	content: url(@/static/common/noticed.png);
}

::v-deep.el-dropdown-menu__item:focus .icon3,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover .icon3 {
	content: url(@/static/common/outed.png);
}

::v-deep.el-dropdown-menu {
	width: 122px;
	min-width: 122px;
	right: 0;
}
</style>
