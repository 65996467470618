<template>
    <div>
        <!-- <div class="seat"></div> -->
        <div class="com-footer">
            <div class="flex_row_around title">
                <div @click="toAgree(1)">用户协议</div>
                <!-- <div @click="toAgree(2)">隐私协议</div>
                <div @click="toAgree(3)">实名认证免责声明</div> -->
            </div>
            <div class="flex_row_center">
                <p>©2022 All Rights Reserved. 品评互动网络科技（北京）有限公司 版权所有 | 地址：北京市通州区京洲园413号楼1层01-2892 | 电话：13552883285 |
                    <span style="cursor: pointer;" @click="toOther()">京ICP备2023002704号</span>
                </p>
                <img class="icon" src="@/assets/footicon.png" alt="">
            </div>
            <!-- <p>网络文化经营许可证：京网文[2023]1234-5678</p> -->
            <p>违法不良信息举报电话：010-67196565 | 北京市互联网举报中心 | 网上有害信息举报专区</p>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
export default {
    name: 'pFooter',
    components: {

    },
    data() {
        return {

        };
    },
    props: {
        index: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        toAgree(id) {
            // this.$router.push('/agreement?id=' + id)
            let routeData = this.$router.resolve({
                path: "/agreement",
                query: { id: `${id}` },
            });
            window.open(routeData.href, "_blank");
        },
        toOther() {
            window.open('https://beian.miit.gov.cn/#/Integrated/index', "_blank");
        }
    }
};
</script>

<style scoped>
.seat {
    height: 181px;
    background: transparent;
}

.com-footer {
    width: 100%;
    min-width: 1200px;
    height: 181px;
    background: #282828;
    border-radius: 0px 0px 0px 0px;
    /* position: fixed; */
    bottom: 0;
    left: 0;
}

.title {
    white-space: nowrap;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 76px;
    cursor: pointer;
}

p {
    font-size: 13px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    text-align: center;
    margin: 4px 0;
}

.icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
}
</style>
