import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store/index';
import Cookies from 'js-cookie'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router';
import Header from '@/components/header/header.vue'
import Footer from '@/components/footer/footer.vue'
// 分页组件
import Pagination from "@/components/Pagination/index.vue";

import gobal from '@/utils/global.js'
// import { setToken } from '@/utils/auth'
import Api from '@/utils/index.js'
import avatarimg from '@/static/home/avatar.png'

Vue.use(gobal)
Vue.use(VueAxios, axios)
Vue.use(ElementUI);
Vue.use(Cookies)
Vue.config.productionTip = false
Vue.component('p-Header', Header) // 全局头部
Vue.component('p-Footer', Footer) // 全局底部
Vue.component('p-Pagination', Pagination)
Vue.prototype.$Api = Api
Vue.prototype.$store = store
Vue.prototype.avatarimg = avatarimg
// 正式版
Vue.prototype.$HTTP = 'https://api.pping.vip/api/';
axios.defaults.baseURL = 'https://api.pping.vip/api/';
// // 正式版
// Vue.prototype.$HTTP = 'http://pinping.04nl.com/api/';
// axios.defaults.baseURL = 'http://pinping.04nl.com/api/';
// http://api.pping.vip/api/
// 测试版
// axios.defaults.baseURL = 'http://jgeq9w.natappfree.cc/api/';
// Vue.prototype.HTTP = 'http://jgeq9w.natappfree.cc/api/';

Vue.prototype.imgUrl = 'https://pinping.04nl.com/';
router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV == 'development') { //开发环境
    // setToken('a408c7cd-581e-4862-a55f-2bddd240d2d2')
    next();
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  // console.log(from)
})


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
