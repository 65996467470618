import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// 用来存储数据
const state = {
    isLogin: false,
    isShowLogin: false
}

// 操作数据
const mutations = {
    UPDATAINFO(state, info) { // 更新用户信息
        for (let key in info) {
            Vue.set(state, key, info[key])
        }
    },
    CHANGELOGINSTATUS(state, status) {
        state.isLogin = status
    },
    CHANGESHOWLOGIN(state, status) {
        state.isShowLogin = status
    },
}
// 响应组件中的事件
const actions = {

}
// 用来将state数据进行加工
const getters = {

}
// 新建并暴露store
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})
