<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: 'PingFang';
  margin: 0;
  background: #FBFBFB;
}

::-webkit-scrollbar,
html::-webkit-scrollbar {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.1);
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
  border-radius: 4px;
  width: 4px;
  height: 4px;
  background: rgba(235, 53, 53, 0.1);
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>
<style src="../common.css"></style>
