<template>
    <div class="rightside">
        <!-- <div class="flex_row_left downloadpart">
            <img class="qrimg" src="@/static/common/qrcode.png" alt="">
            <div @click="isShowDown = true">
                <div class="download">下载红墨笔App ></div>
                <div class="more">查看更多行业资讯</div>
            </div>
        </div> -->
        <div class="list">
            <div class="flex_row_left">
                <div class="redline"></div>
                <div class="alltitle">热门文章</div>
            </div>
            <div class="item flex_row_left" v-for="(e, i) in list" :key="i" @click="toDetail(e.id, e)">
                <div class="flex1">
                    <div class="title">{{ e.title }}</div>
                    <!-- <div class="content overline2">{{e.info}}</div> -->
                    <div class="flex_row_between info">
                        <div class="flex_row_left">
                            <img class="icon" src="@/static/common/eye.png" alt="">
                            <div>{{ e.ping }}</div>
                        </div>
                        <div>{{ e.time }}</div>
                    </div>
                </div>
                <img class="listimg" :src="getImg(e.img)" alt="">
            </div>
        </div>
        <transition name="el-fade-in">
            <DownLoad v-if="isShowDown" @closeDownLoad="closeDownLoad()"></DownLoad>
        </transition>
    </div>
</template>

<script>
import DownLoad from '../download2/download.vue';
import { getUserInfo } from '@/utils/auth'
export default {
    name: 'RightSide',
    components: {
        DownLoad
    },
    props: {
        list: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            mobile: '',
            isShowDown: false
        };
    },
    created: function () {
        this.mobile = getUserInfo().mobile;
    },
    methods: {
        toDetail(id, e) {
            if (e.pay && this.mobile != '13212341234') {
                this.isShowDown = true;
            } else {
                this.$router.push('/articleDetail?id=' + id)
            }
            // if (this.mobile == '13212341234') {
            //     this.$router.push('/articleDetail?id=' + id)
            // } else {
            //     this.isShowDown = true;
            // }
        },
        getImg(img) {
            // return require('@/static/img/' + img + '.png')
            if (img.length > 2) {
                return img
            } else {
                return require('@/static/img/' + img + '.png')
            }
        },
        closeDownLoad() {
            this.isShowDown = false;
        }
    }
};
</script>

<style scoped>
.rightside {
    width: 272px;
    height: auto;
    background: transparent;
    border-radius: 4px 4px 4px 4px;
}

.qrimg {
    width: 68px;
    height: 68px;
    margin: 0 32px 0 18px;
}

.downloadpart {
    padding: 12px 18px;
    background: #FFFFFF;
}

.download {
    height: 24px;
    font-size: 16px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    cursor: pointer;
    white-space: nowrap;
}

.more {
    font-size: 14px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 21px;
    margin-top: 5px;
    cursor: pointer;

}

.list {
    box-sizing: border-box;
    padding: 12px;
    background: #FFFFFF;
    margin-top: 18px;
}

.redline {
    width: 4px;
    height: 18px;
    background: #EB3535;
    margin-right: 6px;
}

.alltitle {
    font-size: 16px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

.item {
    height: 98px;
    box-sizing: border-box;
    padding: 16px 0;
    border-bottom: 1px solid #EBEBEB;
    cursor: pointer;
}

.listimg {
    width: 88px;
    height: 68px;
    margin-left: 10px;
}

.title {
    font-size: 13px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
}

.content {
    font-size: 11px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
}

.icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
}

.info {
    font-size: 10px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    margin-top: 5px;
}
</style>
