<template>
    <div>
        <p-Header :index="5" :searchWord="searchWord" />

        <div class="flex_row_between mainpart">
            <div class="listpart">
                <div class="count">共搜到{{ total ? total : '0' }}条相关信息</div>

                <div v-if="list && list.length > 0">
                    <ArticleList :list="list"></ArticleList>
                    <p-Pagination :total="total" :page.sync="filterParams.page" :limit.sync="filterParams.page_size"
                        @pagination="getList" />
                </div>
                <div v-else>
                    <el-empty :image="require('../../static/common/empty.png')" :image-size="255"
                        description="咦，啥也没有搜到"></el-empty>
                </div>

            </div>
            <RightSide></RightSide>
        </div>
        <p-Footer />
    </div>
</template>

<script>
import ArticleList from '@/components/article/article.vue';
import RightSide from '@/components/rightside/rightside.vue';
export default {
    components: {
        RightSide,
        ArticleList
    },
    data() {
        return {
            list: [],
            // 查询参数
            filterParams: {
                page: 1,
                page_size: 10,
            },
            total: 0,
            searchWord: ''
        };
    },
    created: function () {
        if (this.$route.query.searchWord) {
            this.searchWord = this.$route.query.searchWord;
            this.getList()
        } else {
            this.$router.go(-1)
        }
    },
    methods: {
        getList() {
            this.axios.post(this.$Api.article.search, { ...this.filterParams, content: this.searchWord }).then(res => {
                this.list = res.data.list
                this.total = res.data.count
            })
        }
    }
};
</script>

<style scoped>
.mainpart {
    width: 1000px;
    height: auto;
    padding: 18px 0;
    align-items: flex-start;
    margin: 0 auto;
}

.listpart {
    flex: 1;
    margin-right: 18px;
    background: #fff;
    padding: 0 18px;
}

.count {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 38px;
    border-bottom: 1px solid #EBEBEB;
}




::v-deep .el-tabs__nav-wrap::after {
    background-color: #EBEBEB;
    height: 1px;
}

::v-deep .el-tabs__item.is-active {
    color: #EB3535;
}

::v-deep .el-tabs__active-bar {
    height: 1px;
    background-color: #EB3535;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #EB3535;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #EB3535;
    color: #fff;
}

::v-deep.el-empty {
    padding-top: 53px;
}
</style>
