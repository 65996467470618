<template>
	<div>
		<p-Header :index="activeName" />

		<div class="flex_row_between mainpart">
			<div class="listpart">
				<div class="flex_row_between">
					<el-carousel :interval="5000" arrow="arrow" height="270px" class="flex1">
						<el-carousel-item v-for="(item, i) in ba" :key="i" @click="toDetail(item.id, item)">
							<div>
								<!-- <img class="banner" src="@/static/img/s1.png" alt=""> -->
								<img class="banner" :src="getImg(item.img)" alt="">
								<div class="gradual" @click="toDetail(item.id, item)"></div>
								<div class="bannertitle overline1">{{ item.info }}</div>
							</div>
						</el-carousel-item>
					</el-carousel>
					<div class="flex_col_between" style="height: 270px;">
						<div class="exm" v-for="(item, i) in bb" :key="i" @click="toDetail(item.id, item)">
							<img :src="getImg(item.img)" alt="">
							<div class="gradual"></div>
							<div class="subbannertitle overline1">{{ item.info }}</div>
						</div>
					</div>
				</div>
				<div class="list">
					<!-- <el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="全部" name="first"></el-tab-pane>
						<el-tab-pane label="汽车" name="second"></el-tab-pane>
						<el-tab-pane label="特妆" name="third"></el-tab-pane>
						<el-tab-pane label="母婴" name="fourth"></el-tab-pane>
						<el-tab-pane label="手机" name="five"></el-tab-pane>
					</el-tabs> -->
					<div v-if="activeName == 'first'">
						<ArticleList :list="list"></ArticleList>
						<!-- <el-pagination style="margin-top: 20px;" background layout="prev, pager, next" :total="100">
							</el-pagination> -->
						<p-Pagination :total="total" :page.sync="filterParams.pageNum" :limit.sync="filterParams.pageSize"
							@pagination="getList" />
					</div>
					<div v-if="activeName == 'second'">
						<ArticleList :list="list1"></ArticleList>
						<!-- <el-pagination style="margin-top: 20px;" background layout="prev, pager, next" :total="100">
							</el-pagination> -->
						<p-Pagination :total="4" :page.sync="filterParams.pageNum" :limit.sync="filterParams.pageSize"
							@pagination="getList" />
					</div>
					<div v-if="activeName == 'third'">
						<ArticleList :list="list2"></ArticleList>
						<!-- <el-pagination style="margin-top: 20px;" background layout="prev, pager, next" :total="100">
							</el-pagination> -->
						<p-Pagination :total="3" :page.sync="filterParams.pageNum" :limit.sync="filterParams.pageSize"
							@pagination="getList" />
					</div>
					<div v-if="activeName == 'fourth'">
						<ArticleList :list="list3"></ArticleList>
						<!-- <el-pagination style="margin-top: 20px;" background layout="prev, pager, next" :total="100">
							</el-pagination> -->
						<p-Pagination :total="4" :page.sync="filterParams.pageNum" :limit.sync="filterParams.pageSize"
							@pagination="getList" />
					</div>
					<div v-if="activeName == 'five'">
						<ArticleList :list="list4"></ArticleList>
						<!-- <el-pagination style="margin-top: 20px;" background layout="prev, pager, next" :total="100">
							</el-pagination> -->
						<p-Pagination :total="15" :page.sync="filterParams.pageNum" :limit.sync="filterParams.pageSize"
							@pagination="getList" />
					</div>
					<!-- <div v-else>
						<el-empty :image="require('../../static/common/empty.png')" :image-size="255"
							description="咦，啥也没有搜到"></el-empty>
					</div> -->

				</div>

			</div>
			<RightSide :list="list"></RightSide>
		</div>
		<p-Footer />
		<transition name="el-fade-in">
			<DownLoad v-if="isShowDown" @closeDownLoad="closeDownLoad()"></DownLoad>
		</transition>
	</div>
</template>

<script>
import ArticleList from '@/components/article/article.vue';

import RightSide from '@/components/rightside/rightside.vue';
import DownLoad from '@/components/download2/download.vue';
import { getUserInfo } from '@/utils/auth'
export default {
	components: {
		RightSide,
		ArticleList,
		DownLoad
	},
	data() {
		return {
			mobile: '',
			isShowDown: false,
			activeName: 'first',
			list1: [{
				id: '1',
				img: 'b1',
				title: '什么是跟妆',
				info: '跟妆是指在婚礼、晚宴、颁奖典礼等场合，化妆师为客人进行现场补妆的服务，确保妆容保持完美。',
				name: '环保人',
				time: '2023-01-13',

			}, {
				id: '2',
				img: 'f1',
				title: '什么东西可去角质',
				info: '这些去角质产品中含有化学性成分，能够通过溶解老旧的角质层来使皮肤变得平滑。',
				name: '爱笑的他',
				time: '2023-01-13',
			}, {
				id: '3',
				img: 'c2',
				title: '干货！化妆品专业知识的大汇总（珍藏版）',
				info: '化妆品基础知识的综合讲解，是化妆品专业知识的大汇总，文章会有点长，但绝对值得一读，建议收藏。',
				name: '玫瑰',
				time: '2023-02-28',
			}, {
				id: '4',
				img: 'c1',
				title: '零基础怎么学习化妆？',
				info: '化妆，对于很多人都不陌生，但是操作起来却问题重重。特别对于初学者，更是一种意志锻炼，耐心的磨炼，用心的展现，那么如何才能让化妆初学者能够迅速进入化妆的状态呢？',
				name: '爱教化妆',
				time: '2023-03-15',
			},],
			list2: [{
				id: '5',
				img: 'f2',
				title: '5年化妆心得，总结了这10个妆容技巧！',
				info: '今日份干货分享是我的妆容小心得，共计10个，如果你化妆中也有这些疑惑，或者已经走入了某些误区，那就一定要跟我一起看看。',
				name: '飞翔的眼影盘',
				time: '2023-01-06',
			}, {
				id: '6',
				img: 'f1',
				title: '23条实战化妆小心得，看完你就不会是那个被人笑话的小白了',
				info: '绞尽脑汁总结了23条，化妆的心得体会分享给你们，希望大家在疫情期间也能美美哒。',
				name: '小白',
				time: '2023-01-26',
			}, {
				id: '7',
				img: 's1',
				title: '今天我来和大家聊一下我的美妆心得',
				info: '我们在化妆之前一定要让皮肤很稳定，让皮肤很湿润，这样上妆才会更服帖，也不会卡粉。特别是像我这样的干皮，如果妆前保湿没有做好后面就会出现卡粉的现象！。',
				name: '华老师',
				time: '2023-02-08',
			},],
			list3: [{
				id: '8',
				img: 's1',
				title: '2023年第52届郑州国际美博会',
				info: '自98年以来，ZIBCE已成功举办了51届，历届展会群商云集，馆内人声鼎沸，成交额届届创新高，成为众多美容化妆品公司、美容院、专业观众所青睐的目标展会，ZIBCE 2005年已成为继广州、上海美博会之后中国第三大专业美容行业盛会，展会涵盖了河南、山东、安徽、河北、陕西、山西、湖北、江苏、天津、新疆九省一市在业界享有极高声誉。',
				name: '郑州百事通',
				time: '2023-03-01',
			}, {
				id: '9',
				img: 'f1',
				title: '2023中国（重庆）国际美容化妆品博览会',
				info: '重庆——中国新一线城市，人口3372万，是中国管辖面积最大的直辖市，西部地区多功能的现代工商业城市，长江上游地区经济及金融中心、国家历史文化名城。',
				name: '重庆之光',
				time: '2023-04-03',
			}, {
				id: '10',
				img: 'c1',
				title: '2021 中国（青岛）国际美容化妆品博览会',
				info: '爱美之心，人皆有之。现如今当代女性对自己越来越舍得了，平时买菜时可能会为了一两块钱计较一下，但在让自己变美这一方面，却是花钱毫不手软，尤其化妆护理、瘦身美容等观念不断通过大众媒体的猛烈宣传攻势，几乎激起了每个女人爱美的天性欲望，越来越多的消费者把“爱美”从“心动”变成实实在在花钱的“行动”，直接带动了我国美容产业的蓬勃发展。',
				name: '美容大王',
				time: '2023-02-23',
			}, {
				id: '11',
				img: 'f2',
				title: '中国美容博览会（CBE）化妆品展',
				info: '化妆，对于很多人都不陌生，但是操作起来却问题重重。特别对于初学者，更是一种意志锻炼，耐心的磨炼，用心的展现，那么如何才能让化妆初学者能够迅速进入化妆的状态呢？',
				name: '小乐爱吃饭',
				time: '2023-04-10',
			},],
			list4: [{
				id: '12',
				title: '美妆品牌Charlotte Tilbury被大批投诉，这波神操作让人目瞪口呆',
				info: '知名美妆品牌Charlotte Tilbury的小程序，存在恶意营销，欺骗消费者行为，以下简称为CT品牌。',
				img: 'b1',
				name: '环保人',
				time: '2023-01-13',
				pay: true
			}, {
				id: '13',
				title: '国家药监局连续通告问题化妆品，涉巴黎欧莱雅、兰姿、兰蔻等',
				info: '国家药监局连续两日发布关于问题化妆品的检验结果公告。2月13日，51批次化妆品因不符合规定被通告，其中的标签标示涉及“巴黎欧莱雅（香港）”“广州兰姿”“法国兰蔻”等品牌。',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/ea9ca3edda6a43e823842f91ce507fd4.jpg',
				name: '报料人',
				time: '2023-03-16',
				pay: true
			}, {
				id: '14',
				title: '美妆巨头涨价提业绩，贵了200元的“小灯泡”你还买单吗？',
				info: '涨价对于美妆及奢侈品品牌而言，是一个维持品牌高端定位的常规动作。特别是在2022年业绩下滑的背景下，国际美妆巨头需要通过涨价来提振业绩。',
				img: 'https://p4.itc.cn/q_70/images01/20230404/8d68f96d93d3491aaad91f6d48df6ae3.jpeg',
				name: '花之岛',
				time: '2023-02-17',
				pay: true
			}, {
				id: '15',
				title: '迟不发货被投诉，珀莱雅怎么了',
				info: '珀莱雅再一次陷入舆论漩涡，这一次是因为长时间不发货。',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/299dc5e8c67ddba7fbdb1fb04ed61b96.jpg',
				name: '逆时',
				time: '2023-01-27',
				pay: true
			}, {
				id: '16',
				title: '珀莱雅防晒质量问题导致烂脸概不负责',
				info: '珀莱雅防晒质量问题导致烂脸概不负责,消费者权益得不到保障',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/ffe30bf34c688ea0e0c8e9b7d8ec85b6.jpg',
				name: '逆时',
				time: '2022-12-14',
				pay: true
			}, {
				id: '17',
				title: '零基础学化妆，告诉你正确的化妆步骤！',
				info: '很多女人想化妆，但却不会化妆，还有一部分女人喜欢化妆，却不知道很多地方是错的，今天就带姑娘们一起来全面认识一下化妆步骤，教你从零开始学化妆，告诉你正确的化妆步骤应该是怎样的，哪些地雷不能踩，尽量避开护肤禁忌，打造精致美妆哦，赶紧学起来。',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/7a72c5331f7fa30e38cfefd430b2b6fa.jpg',
				name: '美丽传说',
				time: '2023-01-13',
				pay: true
			}, {
				id: '18',
				title: '盘点新手化妆最容易遇到的10个大坑！你中了几个？',
				info: '这10个化妆技巧能帮助你如何躲过蜕变之路的各种大坑，让自己更加自信和美丽。',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/9d7146e626dd5d1dabd3afd50b1afc15.jpg',
				name: '金枝女孽',
				time: '2023-01-16',
				pay: true
			}, {
				id: '19',
				title: '新手化妆可能会遇到哪些坑？',
				info: '有些女生在上大学前不化妆，进入大学后，发现周围很多女生都化妆，因此也想把自己打扮得美美哒。但是作为新手，在化妆时容易走进误区。下面来说说新手化妆容易犯什么错误',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/4b58f3240989df0e4d439edab25f27d8.jpg',
				name: '名妆',
				time: '2023-02-07',
				pay: true
			}, {
				id: '20',
				title: '国内影视化妆学校需要避免遇到哪些坑？',
				info: '其实国内的影视化妆学校还是很少的，找一个好的影视化妆学校并不容易，由其是对北上广的学员来说，在你找学校的过程中就会碰到很多坑，不了解的同学估计就掉坑里了，小编总结一下关于影视化妆学校的坑，给大家简单的说一说',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/ae1746b116e24eae1b979dca54be5fc3.jpg',
				name: '天冉物语',
				time: '2023-02-18',
				pay: true
			}, {
				id: '21',
				title: '国内影视化妆学校需要避免遇到哪些坑？',
				info: '其实国内的影视化妆学校还是很少的，找一个好的影视化妆学校并不容易，由其是对北上广的学员来说，在你找学校的过程中就会碰到很多坑，不了解的同学估计就掉坑里了，小编总结一下关于影视化妆学校的坑，给大家简单的说一说。',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/ce05dc6681b951e50da0577b87e69518.jpg',
				name: '欣美',
				time: '2023-02-07',
				pay: true
			}, {
				id: '22',
				title: '想上国内化妆学校，需要避免遇到这些坑',
				info: '学校的招生手段中，预定课程是很常见的，预定了就要交定金，正归学校预定课程的定金是非常少的，但有一些学校为了留住学员，定金要交几千块钱，然后这个定金还是不退的，也就是说如果你不想在这个学校学习了，那这几千就白花了，类似这种预定的问题有很多，下面小编给大家简单讲一下。',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/c887aa02e4f6066947250a0067b4a62c.jpg',
				name: '知己',
				time: '2023-03-24',
				pay: true
			}, {
				id: '23',
				title: '化妆为什么会显老气没有少女感？',
				info: '为什么一化妆就显老？化完妆感觉没什么改善？是不是不适合化妆？……其实按照珍珠这一年多的改妆来看，90%以上的姐妹化完妆都会好看很多。前提是你要找对路。',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/6bcb1d866125d7508e4dcb4bac5ad986.jpg',
				name: '方珍珠',
				time: '2023-04-01',
				pay: true
			}, {
				id: '24',
				title: '关于染发，如何找到适合自己的发色？',
				info: '做发型离不开洗剪染烫吹，染发这个环节看似常规，但选到不适合的发色，气色直接面如死灰……',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/5202b2d89918a582638cd7f1dc45a092.jpg',
				name: '美发',
				time: '2023-03-21',
				pay: true
			}, {
				id: '25',
				title: '怎样用妆容弥补硬件瑕疵',
				info: '这两年突然在某音刷到很多美妆类素人博主，啥是素人博主呢？',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/d5bfa1f56dbfc8f7594dcc57b22d6f69.jpg',
				name: '丽人行',
				time: '2023-03-27',
				pay: true
			}, {
				id: '26',
				title: '变美也有「平台期」？普通人如何摆脱无效变美',
				info: '正如减肥一样，变美也有平台期，而且对不少姐妹来说，变美的平台期更难察觉和突破。尤其是原生条件不差的姐妹，很容易陷入「中等陷阱」里，花费大把时间和金钱，却达不到提升的效果',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/37fae5adef1a70a5b0fd9519c943e5c2.jpg',
				name: '海静美妆',
				time: '2023-04-06',
				pay: true
			}
				// , {
				// 	id: '8',
				// 	title: '',
				// 	info: '',
				// 	img: 's1',
				// 	name: '刘作虎',
				// 	time: '2023-01-13',
				// }
			],
			ba: [
				{
					id: '12',
					img: 'b1',
					info: '美妆品牌Charlotte Tilbury被大批投诉',
					pay: true
				},
				{
					id: '6',
					img: 'f1',
					info: '23条实战化妆小心得，看完你就不会是那个被人笑话的小白了'
				},
				{
					id: '7',
					img: 's1',
					info: '今天我来和大家聊一下我的美妆心得'
				}
			],
			bb: [
				{
					id: '12',
					img: 'b1',
					info: '美妆品牌Charlotte Tilbury被大批投诉',
					pay: true
				},
				{
					id: '6',
					img: 'f1',
					info: '23条实战化妆小心得，看完你就不会是那个被人笑话的小白了',
					pay: false
				}
			],
			list: [{
				id: '1',
				img: 'b1',
				title: '什么是跟妆',
				info: '跟妆是指在婚礼、晚宴、颁奖典礼等场合，化妆师为客人进行现场补妆的服务，确保妆容保持完美。',
				name: '环保人',
				time: '2023-01-13',
			}, {
				id: '5',
				img: 'f2',
				title: '5年化妆心得，总结了这10个妆容技巧！',
				info: '今日份干货分享是我的妆容小心得，共计10个，如果你化妆中也有这些疑惑，或者已经走入了某些误区，那就一定要跟我一起看看。',
				name: '飞翔的眼影盘',
				time: '2023-01-06',
			}, {
				id: '9',
				img: 'f1',
				title: '2023中国（重庆）国际美容化妆品博览会',
				info: '重庆——中国新一线城市，人口3372万，是中国管辖面积最大的直辖市，西部地区多功能的现代工商业城市，长江上游地区经济及金融中心、国家历史文化名城。',
				name: '重庆之光',
				time: '2023-04-03',
			}, {
				id: '17',
				title: '零基础学化妆，告诉你正确的化妆步骤！',
				info: '很多女人想化妆，但却不会化妆，还有一部分女人喜欢化妆，却不知道很多地方是错的，今天就带姑娘们一起来全面认识一下化妆步骤，教你从零开始学化妆，告诉你正确的化妆步骤应该是怎样的，哪些地雷不能踩，尽量避开护肤禁忌，打造精致美妆哦，赶紧学起来。',
				img: 'http://pinping.oss-cn-beijing.aliyuncs.com/7a72c5331f7fa30e38cfefd430b2b6fa.jpg',
				name: '美丽传说',
				time: '2023-01-13',
				pay: true
			},
			],

			// 查询参数
			filterParams: {
				pageNum: 1,
				pageSize: 10,
			},
			total: 1,

		};
	},
	created: function () {
		console.log(123)
		console.log(this.$route)
		if (this.$route.query.activeName) {
			this.activeName = this.$route.query.activeName;
		}
		this.mobile = getUserInfo().mobile;
	},
	methods: {
		toDetail(id, e) {
			if (e.pay && this.mobile != '13212341234') {
				this.isShowDown = true;
			} else {
				this.$router.push('/articleDetail?id=' + id)
			}
			// if (this.mobile == '13212341234') {
			// 	this.$router.push('/articleDetail?id=' + id)
			// } else {
			// 	this.isShowDown = true;
			// }
		},
		closeDownLoad() {
			this.isShowDown = false;
		},
		getImg(img) {
			return require('@/static/img/' + img + '.png')
		},
		handleClick(tab, event) {
			console.log(tab.name);
		},
		getList() {

		}
	}
};
</script>

<style scoped>
.mainpart {
	width: 1000px;
	height: auto;
	padding: 18px 0;
	align-items: flex-start;
	margin: 0 auto;
}

.listpart {
	flex: 1;
	margin-right: 18px;
}

.exm {
	width: 198px;
	height: 129px;
	margin-left: 12px;
	position: relative;
}

.exm img,
.banner {
	width: 100%;
	height: 100%;
}

.bannertitle {
	font-size: 18px;
	font-family: Source Han Sans CN-Medium, Source Han Sans CN;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 21px;
	position: absolute;
	left: 16px;
	bottom: 16px;
}

.subbannertitle {
	font-size: 14px;
	font-family: Source Han Sans CN-Normal, Source Han Sans CN;
	font-weight: 400;
	color: #FFFFFF;
	position: absolute;
	left: 8px;
	bottom: 8px;
}

.gradual {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}

.listpart ::v-deep .el-carousel__indicators--horizontal {
	left: unset;
	right: -20px;
}

.listpart ::v-deep .el-carousel__button {
	width: 8px;
	height: 8px;
	border-radius: 50%;
}

.list {
	box-sizing: border-box;
	margin-top: 18px;
	padding: 0 16px;
	background: #FFFFFF;
}

::v-deep .el-tabs__nav-wrap::after {
	background-color: #EBEBEB;
	height: 1px;
}

::v-deep .el-tabs__item.is-active {
	color: #EB3535;
}

::v-deep .el-tabs__active-bar {
	height: 1px;
	background-color: #EB3535;
}

::v-deep .el-tabs__item:hover {
	color: #EB3535;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
	color: #EB3535;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #EB3535;
	color: #fff;
}
</style>