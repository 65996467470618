//1.引入vue
import Vue from "vue"
//2.引入vue-router
import VueRouter from "vue-router";
import home from '@/views/home/home.vue'
import search from "@/views/home/search.vue";
import articleDetail from "@/views/home/articleDetail.vue";
//3.注册vue-router
Vue.use(VueRouter);
//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//4.路径
const routes = [
  {
    path: "/",
    name: "home",
    component: home,
    meta: {
      auth: true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: search,
    meta: {
      auth: true
    }
  },
  {
    path: '/articleDetail',
    name: 'articleDetail',
    component: articleDetail,
    meta: {
      auth: true
    }
  },
  {
    path: "/find",
    name: "find",
    component: () => import("@/views/find/index.vue"),
    children: [
      // {
      //   path: "home",
      //   name: "home",
      //   component: () => import("@/views/home/home.vue"),
      // },
    ],
  },
  {
    path: "/finddetail",
    name: "finddetail",
    component: () => import("@/views/find/finddetail.vue"),
  },
  {
    path: "/publish",
    name: "publish",
    component: () => import("@/views/publish/index.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/lawyers",
    name: "lawyers",
    component: () => import("@/views/lawyers/index.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/lawyerdetail",
    name: "lawyerdetail",
    component: () => import("@/views/lawyers/lawyerdetail.vue"),
    meta: {
      auth: true
    }
  }, {
    path: "/optimize",
    name: "optimize",
    component: () => import("@/views/optimize/index.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/center",
    name: "center",
    component: () => import("@/views/user/index.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/user/message.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("@/views/user/agreement.vue"),
    meta: {
      auth: true
    }
  },
  {
    path: "/set",
    redirect: '/set/edit',
    name: "set",
    component: () => import("@/views/user/set.vue"),
    children: [
      {
        path: "edit",
        name: "edit",
        component: () => import("@/views/user/edit.vue"),
      },
      {
        path: "safe",
        name: "safe",
        component: () => import("@/views/user/safe.vue"),
      },
      {
        path: "privacy",
        name: "privacy",
        component: () => import("@/views/user/privacy.vue"),
      },
      {
        path: "notice",
        name: "notice",
        component: () => import("@/views/user/notice.vue"),
      },
      {
        path: "feedback",
        name: "feedback",
        component: () => import("@/views/user/feedback.vue"),
      }, {
        path: "service",
        name: "service",
        component: () => import("@/views/user/service.vue"),
      },
    ],
  },
];
//4.创建
const router = new VueRouter({
  mode: 'history',//模式
  base: "/home",//根路径
  routes,
});
//5.共享
export default router;
