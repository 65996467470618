<template>
    <div class="downLoad">
        <div class="balckmask" style="z-index: 10;">
            <div class="box">
                <img @click="closeD()" class="close" src="@/static/common/closedownload.png" alt="">

                <div>
                    <div class="download">下载红墨笔App ></div>
                    <div class="more">查看更多行业资讯</div>
                </div>
                <div class="whitebg">
                    <img class="qrimg" src="@/static/common/qrcode.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'downLoad',
    components: {

    },
    data() {
        return {
        };
    },
    props: {
        index: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        closeD() {
            this.$emit('closeDownLoad')
        },
    }
};
</script>

<style scoped>
.box {
    width: 256px;
    height: 318px;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    background-image: url(@/static/common/redbg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 18px;
}

.close {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.qrimg {
    width: 196px;
    height: 196px;
    margin: auto;
}

.download {
    font-size: 18px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 27px;
    white-space: nowrap;
    text-align: center;
}

.more {
    font-size: 14px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
    white-space: nowrap;
    text-align: center;
}

.whitebg {
    width: 220px;
    height: 220px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px 8px 8px 8px;
    margin: 14px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
