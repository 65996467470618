import { render, staticRenderFns } from "./rightside.vue?vue&type=template&id=7bbc7669&scoped=true&"
import script from "./rightside.vue?vue&type=script&lang=js&"
export * from "./rightside.vue?vue&type=script&lang=js&"
import style0 from "./rightside.vue?vue&type=style&index=0&id=7bbc7669&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bbc7669",
  null
  
)

export default component.exports