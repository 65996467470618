// let ApiRoot = 'http://api.pping.vip/api/'; //红墨笔
// let ApiRoot = 'http://pinping.04nl.com/api/'; //红墨笔
// let ApiRoot = 'http://h2ccqf.natappfree.cc/api/'; //本地测试
let Api = {
	common: {
		verify: 'user/sendCode',
		mobileLogin: "user/mobileLogin",
		quickLogin: "user/quickLogin",
	},
	article: {
		index: 'article/getList',
		search: 'article/search', //搜索
		category: 'article/getHomeMenu',
		detail: 'article/getDetail',
		getComment: 'article/getComment', //获取文章一级评论
		getSecComment: 'article/getSecComment', //获取文章二级评论
		addComment: 'article/addComment',
		addArticleCollect: 'article/addArticleCollect', //收藏
		cancelArticleSave: 'article/cancelArticleSave', //取消收藏
		addArticleLike: 'article/addArticleLike', //文章添加喜欢
		cancelArticleLike: 'article/cancelArticleLike', //文章取消喜欢
		addArticleUnlike: 'article/addArticleUnlike', //文章添加不喜欢
		cancelArticleUnlike: 'article/cancelArticleUnlike', //文章取消不喜欢
		addCommentLike: 'article/addCommentLike', //喜欢评论
		cancelCommentLike: 'article/cancelCommentLike', //取消喜欢评论
		addShare: 'article/addShare', //文章分享接口
		getInitialMenu: 'article/getInitialMenu', //获取带有拼音产品品牌
		hotSearch: 'article/hotSearch', //热门搜索
	},
	find: {
		getList: 'rank/getList', //获取排行榜
		addRanking: 'rank/addRanking', //排行榜点赞
		cancelRanking: 'rank/cancelRanking', //取消排行榜点赞
		survey: 'rank/survey', //排行榜详情页
		getChartInfo: 'rank/getChartInfo', //排行榜详情页基础信息
		getChartData: 'rank/getChartData', //排行榜详情页图表信息
		getRankDetailComment: 'rank/getRankDetailComment', //排行榜一级评论
		getRankDetailSecComment: 'rank/getRankDetailSecComment', //排行榜一级评论
		addRankDetailComment: 'rank/addRankDetailComment', //添加排行榜评论
		likeRanking: 'rank/likeRanking', //喜欢排行榜
		// cancelRanking: 'rank/cancelRanking', //取消喜欢排行榜
		addRankDetailCollect: 'rank/addRankDetailCollect', //收藏文章
		cancelRankDetailCollect: 'rank/cancelRankDetailCollect', //取消收藏
		addShare: 'rank/addShare', //文章分享接口
		addCommentLike: 'rank/addCommentLike', //喜欢排行榜评论
		cancelCommentLike: 'rank/cancelCommentLike', //取消喜欢排行榜评论
	},
	publish: {
		add: 'article/addArticle',
		getTag: 'article/getTag',
		upload: 'user/upload',
		getDraftArticle: 'article/getDraftArticle', //获取未提交文章 is_draft 0 草稿
	},
	lawyers: {
		getList: 'Lawyer/getList', //律师团列表
		detail: 'lawyer/detail', //律师详情
		addConsult: 'lawyer/addConsult', //用户咨询
		total: 'lawyer/total', //处理事件个数
	},
	user: {
		getUserInfo: 'user/getUserInfo', //获取用户基本信息
		articleList: 'user/articleList', //我的笔记
		actionList: 'user/actionList', //我的收藏type='collect'我的点赞type='like'
		profile: 'user/profile', //编辑资料
		changeBio: 'user/changeBio', //编辑简介
		uploadBackendImg: 'user/uploadBackendImg', //编辑背景图
		getConfig: 'set/getConfig', //设置隐私
		updateConfig: 'set/updateConfig', //修改设置
		addFeedback: 'user/addFeedback', //用户反馈
		getNewNotice: 'notice/getNewNotice', //未读消息
		getLikeList: 'notice/getLikeList', //通知栏列表,赞和收藏
		getCommentList: 'notice/getCommentList', //通知栏列表,评论
		changeGuide: 'user/changeGuide', //修改个人引导
		logOff: 'user/logOff', //注销账号
		quickLogin: 'user/quickLogin', //登录 -- 一键登录
		logout: 'user/logout', //退出账号
		certification: 'user/certification', //实名认证
		agreement: 'user/agreement', //协议政策
		getUserAgent: 'user/getUserAgent', //获取用户登录设备信息
		changemobile: 'user/changemobile', //修改手机号
		thirdlogin: 'user/third', //第三方登录
		bindWechat: 'user/bindWechat', //绑定微信
		register: 'user/register', //注册
		login: 'user/login', //登录
	},
}
export default Api
