<template>
    <div>
        <div class="item flex_row_left" v-for="(e, i) in list" :key="i" @click="toDetail(e.id, headimg[i], e)">
            <div class="flex1">
                <div class="title">{{ e.title }}</div>
                <div class="content overline2">{{ e.info }}
                </div>
                <div class="flex_row_left info">
                    <img class="avatar" :src="headimg[i] ? headimg[i] : '@/static/home/avatar.png'" alt="">
                    <div class="nick overline1">{{ e.name }}</div>
                    <div class="flex_row_left">
                        <!-- <div>{{ e.time }}</div>
                        <img class="icon" src="@/static/home/heart.png" alt="">
                        <div>{{ e.ping }}</div>
                        <img class="icon" src="@/static/home/comment.png" alt="">
                        <div>{{ e.news }}</div> -->
                    </div>

                </div>
            </div>
            <img class="listimg" :src="getImg(e.img)" alt="">
        </div>
        <transition name="el-fade-in">
            <DownLoad v-if="isShowDown" @closeDownLoad="closeDownLoad()"></DownLoad>
        </transition>
    </div>
</template>

<script>
import DownLoad from '@/components/download2/download.vue';
import { getUserInfo } from '@/utils/auth'
export default {
    name: 'ArticleList',
    components: {
        DownLoad
    },
    data() {
        return {
            mobile: '',
            isShowDown: false,
            headimg: ["https://thirdwx.qlogo.cn/mmopen/vi_32/xA9ffqibUDU7U7ybcoIKSCqezUR3hh0LaxRr19XHzRLgjwKborkXWphdGo1doxvgDa4wzRRPcKrXz3O6JudTYBw/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIpvVGc5iawPRkk3WgwwTaqXxW1k5bIUSiclSv310OPFtvVxUYYo2pDLibzFTsLebs3edGpMp79iakiavw/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIMYYs4bAvzFTg4j7FDFXkbthjYxvymibBSxLww5VPGrYjoeTLAE9wn7fZsgvzkOibWInE0tCA6YjkQ/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJaqcg4HIRIRW1xQvIyqS6biaAqjnZ5e2g2wWVEMshovRSGCSDBZcD4qIwyIBVIj0YvkzVA821vHjQ/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/Lja6I0vvaX53xGj2GZRtxo0TNvVPs5IDITZ0iaFqW0RXwt7KKRKLHwnHHSjgvYrEOibmYZOeibx7ZejaqS2SmvE7w/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTL97PrD4Yjl3WiadSdhpAaS8gqRdyvWUjeMrSLRztStmpqfSPSSKibnOlKJWTtl3QibCDsmhCw9G2Gxg/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epIQcIdPnl2Jv4WVENKJKGJZ6qlFDx9yVV9NaM3zX6pyHJpudZ6nU3SqcLzx1EricIriaciagwY7ziaQA/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/ibRmAV56xx6UCC9wJcjPzqmZu2NR2HWdJEMic3AeGiahwslg4KsLsYg3ZmqwOPtKqqDLUfGDsLk2j2pVR3WJvicXuw/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIFFZW1GFdfsTGbO6g2dnfAAU87JHe1cMicjUJicFeIz8qNZxuvCRqicEZuLzQpeWL5MBb9pc9nEjG9g/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83erR5gtYk0l6Lz3ZQfN2KWjcJYZljqnX0R1QbOu9532kwzU7gHgRCicGJc0E8I3Y9TNHsqzT5wG112w/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKwAureic771pUbZDEdiaJeRCGianmFnOGHfKoAq807j4IbIYqjiaFBDQRu5S2HntKcXN2pCLWiauLwAww/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/nzZUdicGxm2rQ4Z13jHUTV5JrEH5Oz3WicxHA36GC08UdmqUAJia0P1qcbXGTt6hicRgQL6dhVEHP2rwQyYmRVgf9A/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/esicXn5u4ibc6CwcftgKgVmRCVsC3KrKDOwtefZWFOuL7UrCF56rCbQN3KKbX3nTYKHKDacOSzNQZBKerBsc6ZRg/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/KHYTKOiaawFAQtdHCq9vUPJBzrG75nbxKkyFibQibN27KtfiaMQibIWqtSMcAKC3GAA7USPCiaRubCBv75euaDhRaicDQ/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/HQ3bLTa00tMVYYprG0I2JLoTiadIlezdibENu7L1ic6YBGJ64GQgqDO0qyjkp6ibe4qRNtKpUs7eayLcSiblQ1UGxqA/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/Oe5l9hf8ZPeBjPUYel7ic2RwGSySNl8nu9hLIQ1IJzWBO2pzIark8M52jSVI62eMtlg3TqiacBkZgbiapuc5ialCXA/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eoQ5mstOb8SusKjoWIzNjUyWlH89B8JbUiaGBwhzsiclSCGpby5WG5xyCdzZGxFrBwDiaunMrRAFiahTw/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/B2aichW9qAW19wUdvrVwS2ukNpQdZ3ObACQFZTKH3jQpRV0WGamBwyQOA8x9Om4zPsoEIWJdwuyyTNlBY2llmEg/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eopaAYkibSBpbuDAuBoKCtMiaTT8oBA2pAhjoItxibHT0MFnc9quRULQ6P9ibD14oP8ibW0FLDQv1ROib5w/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epLJ1lJWAZ2LlIEe4xDz4cexFP8QG4GsBQf805b6u0KdfTGOXeSW4doTQM7JJdfw9EhoiaP5VpodBQ/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTItLH6pibFYLuJQ5rg87M9BNWbiaKZkkHribmu9TiciaaMgHpNUG6KfJCic4nzRx1wKLicJXvGbq5DsMbdew/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIPa6d0Lr40Pb2S059AqVE3uXLOib4yuWTbSuJ6pJibd8vpZGZ2KlyvXQteXv286b8M8feLGR36bwQA/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/p83UpvULG4E9MbCfmia1OcakKP4HnoJ1MDuX9ne5RZkPHvq67c6X6AzM92tnjJNOg3ia2mUMuQhaXUYyZKtrusEw/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/hLTzCPpaSNGAAfvGmHPWvbr95mwTDCjkDPGUDZ3fC3UmMOK9qmyJAu6ID7fbFXMOOKhMaGvBLS9Q7Dvia6iaNqVQ/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/JcBuFPqCpXznK2qfSt4icf3DwIPLrlsOgvl8soWewYziclRlk8oA2Q9I9bEAZrMoOdtZnZ8xSicPKvt6xfDdHv1jg/132",
                "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJDdmHm6txEVkhiahN0eIdPz34xpzexRqIZknEmibSVVhNZPDmVhCjje4BZhelIGiatupOjI4oLGVN9w/132"]
        };
    },
    props: {
        list: {
            type: Array,
            default() {
                return []
            }
        },
    },
    created() {
        this.mobile = getUserInfo().mobile;
    },
    methods: {
        closeDownLoad() {
            this.isShowDown = false;
        },
        toDetail(id, headimg, e) {
            if (e.pay && this.mobile != '13212341234') {
                this.isShowDown = true;
            } else {
                this.$router.push('/articleDetail?id=' + id + '&head=' + headimg)
            }
            // if (this.mobile == '13212341234') {
            //     this.$router.push('/articleDetail?id=' + id + '&head=' + headimg)
            // } else {
            //     this.isShowDown = true;
            // }
        },
        getImg(img) {
            console.log(img)
            if (img.length > 2) {
                return img
            } else {
                return require('@/static/img/' + img + '.png')
            }
        },
    }
};
</script>

<style scoped>
.item {
    height: 156px;
    box-sizing: border-box;
    padding: 18px 0;
    border-bottom: 1px solid #EBEBEB;
    cursor: pointer;
}

.listimg {
    width: 132px;
    height: 100px;
    margin-left: 12px;
}

.title {
    height: 27px;
    font-size: 18px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 27px;
}

.content {
    font-size: 15px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 23px;
    margin-top: 8px;
}

.icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
    margin-left: 16px;
}

.info {
    font-size: 10px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    margin-top: 5px;
}

.avatar {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-right: 8px;
}

.nick {
    max-width: 100px;
    font-size: 14px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    margin-right: 16px;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #EB3535;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #EB3535;
    color: #fff;
}
</style>
